<template>
    <div>
        <!-- {{ orderNo }} -->
        <!-- {{ getOrder }} -->
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div v-if="getOrder" class=" p-5">
            
            <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <feather v-if="getOrder.status == 'pending' && getOrder.payment == null" @click="delOrder" size="30" type="trash" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather>
            <h4 class="title is-5 ml-5 pl-5 mb-4 py-1"> {{ getOrder.shop  }} </h4>
            
            <div class="p-3">
                <b-tag class="mb-2" rounded :type="`${getOrder.status == 'completed' ? 'is-primary' : getOrder.status == 'confirmed' ? 'is-link' : 'is-warning'} has-text-weight-semibold`"> {{ getOrder.status }} </b-tag> 
    
                <h4 class="title is-4 pt-2"> Order {{ getOrder.orderNo.split('-')[0]  }} </h4>
                <p class="subtitle "> {{ getOrder.status == 'completed' ? formatAMPM(getOrder.orderDelivered) : formatAMPM(getOrder.createdAt) }}</p>

                <b-tag v-if="getOrder.scheduled" rounded class="mt-" type="is-primary"> Scheduled</b-tag>
                <span v-if="getOrder.scheduled" class="mt-2 small has-text-dark has-text-weight-medium is-flex is-align-items-center ml-0">  <feather size="16" class="has-text-primary mr-1" type="clock"></feather> {{ formatAMPM(getOrder.scheduled) }} </span>
                
            </div>
            
            <!-- <div class="px-3 pt-3 pb-0">
                <h4 class="title is-5 ">
                
                </h4>
            </div> -->
            <div class="px-2">
                <CartTemplate :isControlVisible="false" :isRoutable="false"  :products="getOrder.items" :currency="getOrder.country.currency"/>
            </div>
            <!--  -->
            <!-- Total -->
            <div class="card p-3">
                <h4 class="title is-5 p-2 pt-3">Total</h4>

                <div class="px-2">
                    <div v-if="(getOrder.totalActualPrice !== getOrder.totalPrice)" class="columns is-mobile mb-0">
                        <div class="column">
                            <p>Savings</p>
                        </div>
                        <div class="column has-text-right">
                            <p>({{ getOrder.country.currency }}{{ (getOrder.totalActualPrice - getOrder.totalPrice).toFixed(2) }})</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-semibold">Subtotal</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-semibold">{{ getOrder.country.currency }}{{ (getOrder.totalPrice).toFixed(2) }}</p>
                        </div>
                    </div>
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="">Delivery fee</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="">{{ (getOrder.deliveryFee == 0 ? 'FREE' : getOrder.country.currency+getOrder.deliveryFee.toFixed(2)) }}</p>
                        </div>
                    </div>
                    <hr class="my-3 p-0">
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-bold is-5">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-bold is-5">{{ getOrder.country.currency }}{{ (getOrder.totalPrice + getOrder.deliveryFee).toFixed(2) }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-5 my-2">
                <h4 class="title is-5 mb-4 pt-3">Payment</h4>

                <div style="display:flex;align-content: center;align-items: center">
                    <!-- <feather type="credit-card" class="p-2"></feather>  -->
                    <p icon class=" is-5 is-grey has-text-weight-medium " > {{ getOrder.country.currency+(getOrder.totalPrice + getOrder.deliveryFee).toFixed(2) }}  </p> 
                    
                    <!-- <b-tag class="my-2 mx-2" rounded :type="`${getOrder.status == 'completed' ? 'is-primary' : getOrder.status == 'confirmed' ? 'is-link' : 'is-warning'} has-text-weight-semibold`"> {{ getOrder.scheduled ? 'scheduled' : getOrder.status }} </b-tag> -->
                    <b-tag @click="initPay" class="my-2 mx-2" rounded :type="`${getOrder.payment == null ? 'is-warning' : getOrder.payment.status == 'success' ? 'is-primary' : 'is-warning' } has-text-weight-semibold`"> {{ getOrder.payment == null ? 'pending' : getOrder.payment.status == 'success' ? getOrder.payment.status : getOrder.payment.status }} </b-tag>
                </div>
            </div>

            <div class="card p-5 my-2 " >
                <h5 class="title is-5 mb-4">Delivery</h5>
                <div style="display:flex;align-content: center;align-items: center">
                    
                    <!-- <feather type="map-pin" class="p-2"></feather>  -->
                    <p icon class=" is-5 is-grey has-text-weight-medium "> {{ getOrder.deliveryAddressName }} </p>
                    <p class="small">{{ getOrder.deliveryNote }}</p>
                </div>
            </div>

            <div class="card p-3 my-2">
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="is-flex is-justify-content-left is-align-items-center">
                            <feather :type="getOrder.scheduled ? 'calendar' : 'clock'" size="20" class="p-2"></feather>
                            <h4 class=" is-6 has-text-weight-medium"> {{ getOrder.scheduled ? formatAMPM(getOrder.scheduled) : getOrder.deliveryTime }} </h4>
                        </div>
                        <!-- <h6 class="">Same day delivery</h6> -->
                    </div>
                </div>
            </div>
            
            <div class="card p-4 my-2">
                <div class="columns is-mobile p-2 pb-0">
                        <div class="column">
                            <p class="title is-5 has-text-weight-semibold">Total</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="title is-5 has-text-weight-semibold">{{ getOrder.country.currency }}{{ (getOrder.totalPrice + getOrder.deliveryFee).toFixed(2) }}</p>
                        </div>
                </div>
                <b-button v-if="getOrder.status == 'completed' && getOrder.scheduled" @click="orderAgain" type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded> Order again </b-button>
            </div>
        </div>
        <b-modal v-model="isPmtModalActive" width="460px" full-screen scroll="keep">
                <div class="p-4 has-bg-white is-flex is-justify-content-center is-align-items-center is-flex-direction-column" style="height:100%;margin:">
                       <PayStackVue 
                            v-if="payInt"
                            style="width:100%"
                            :amount="Math.round( (getOrder.totalPrice + getOrder.deliveryFee).toFixed(2) * 100)"
                            :email="getUser.email ? getUser.email : 'hello@shopnscan.app'"
                            :channels="channel"
                            :currencyCode="getOrder.country.currency"
                            :metadata="metadata"
                            :showAmountInButton="true"
                            :reference="reference"
                            :callback="callback"
                            :close="close"
                            ref="paymentBtn"
                        ></PayStackVue>
                    
                    
                </div>
            </b-modal>


    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import CartTemplate from '../components/CartTemplate'
    import GoogleMaps from '../components/GoogleMaps'
    import axios from 'axios'
    // import moment from 'moment'
    import PayStackVue from '@/components/PayStack.vue'


    export default {
        name: "OrderDetails",
        data() {
            return {
                payInt: false,
                isPmtModalActive: false,
                orderNo: null,
                isFullPage: true,
                business: '',
                businessData: null,
                isOpen: true,
                isLoading: false, 
                note: '',
                deliveryTime: '',
                scheduleDate: '',
                metadata: null,
                channel: null,
                datetime: new Date()
            }
        },
        components: {
            CartTemplate,
            PayStackVue
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            getBusiness() {
                return this.$store.getters['vendors/currentBusiness'](this.business)
            },
            getOrder() {
                return this.$store.getters['orders/getOrder'](this.orderNo)
            },
            reference(){
                let text = "";
                let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
                for( let i=0; i < 10; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));
        
                return text;
            }
        },
        mounted() {
            this.orderNo = this.$route.params.orderNo
            // if (this.cartCount <= 0) {
            //     this.$router.push('/index')
            // }
        },
        watch: {
            getOrder() {
                if (this.getOrder) {
                    //
                    this.metadata = {
                                orderNo: this.getOrder.orderNo,
                                firstname: this.getUser.firstname,
                                lastname: this.getUser.lastname,
                            }
                }
            }
        },
        methods: {
            initPay() {
                if (this.getOrder.payment == null ) {
                    this.payInt = true
                    this.isPmtModalActive = true
                } else if (['timeout', 'failed'].includes(this.getOrder.payment.status)) {
                    this.payInt = true
                    this.isPmtModalActive = true
                }
            },
            orderAgain() {

            },
            getTimezone() {
                //
                const zone = new Date().toString().substring(28,33)
                const hr = parseInt(zone.substring(0,3))
                const mn = parseInt(zone.substring(3))
                
                return { hour: hr, minutes: mn }
            },
            goBack() {
                this.$router.back()
            },
            delOrder() {

                this.$buefy.dialog.confirm({
                            title: 'Clear Order',
                            message: `Are you sure you want to clear this Order ${this.getOrder.orderNo.split('-')[0]}.`,
                            confirmText: 'Clear Order',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                // this.$store.dispatch('cart/emptyCart')
                                // this.$router.push('/index')
                                axios.post('/order/delete', {  orderNo: this.getOrder.orderNo, user: { countryCode: this.getUser.phone.substr(0,4),tel: this.getUser.phone.substr(4) }
                                        })
                                        .then( response => {

                                            //
                                            this.$buefy.toast.open("Success!")

                                            // const res = response.data
                                            // this.isLoading = false
                                            console.log(response.data)

                                            setTimeout(()=>{
                                                
                                                this.$router.push('/orders')
                                            }, 1200)

                                        })
                            }
                        })

                
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            completeOrder() {

                this.$buefy.dialog.confirm({
                            title: 'Comfirm Order',
                            message: `Are you sure you want to place this order.`,
                            confirmText: 'Place',
                            type: 'is-primary',
                            // hasIcon: true,
                            onConfirm: () => {
                                this.isLoading = true
                                // this.$store.dispatch('cart/emptyCart')
                                // let phone = this.getUser.phone.substr(0,4)

                                const data = {
                                    items: this.items,
                                    totalActualPrice: this.totalActualPrice,
                                    totalPrice: this.totalPrice,
                                    deliveryFee: parseFloat(this.getBusiness.deliveryFee),
                                    total: (this.getBusiness.freeDelivery ? this.totalPrice : this.totalPrice + this.getBusiness.deliveryFee),
                                    user: { countryCode: this.getUser.phone.substr(0,4),tel: this.getUser.phone.substr(4) },
                                    business: this.getCartBusiness,
                                    payment: this.getUser.paymentMethod,
                                    deliveryAddressName: this.delivery.address,
                                    deliveryAddress: [this.delivery.location.lat, this.delivery.location.lng],
                                    deliveryNote: this.note,
                                    deliveryTime: this.deliveryTime,
                                    scheduled: this.scheduleDate
                                }

                                //
                                axios.post('/order/place', data)
                                .then( response => {

                                    const res = response.data
                                    this.isLoading = false

                                    //
                                    data.orderNo = res.orderNo
                                    this.$store.dispatch('orders/placeOrder', data)
                                    
                                    // clear 
                                    this.$store.dispatch('cart/emptyCart')

                                    if (this.scheduleDate) {
                                        //
                                        this.$buefy.toast.open('Order Scheduled Successfully.')

                                    } else {
                                        //
                                        this.$buefy.toast.open('Order Tracking in progress.')
                                    }
                                    //
                                    setTimeout(()=>{
                                                
                                                this.$router.push('/tracker')
                                            }, 1200)

                                    // this.$buefy.dialog.confirm({
                                    //     title: 'Order Tracking',
                                    //     message: res.message+', Track your order!',
                                    //     onConfirm: () => {
                                    //         //
                                    //         setTimeout(()=>{
                                                
                                    //             this.$router.push('/tracker')
                                    //         }, 800)
                                    //     }
                                    // })

                                }).catch(error=>{
                                    this.isLoading = false
                                    this.$buefy.dialog.alert(error.response.data)
                                    console.log(error.response)
                                })

                                // setTimeout(()=>{
                                //     this.isLoading = false
                                //     this.$router.push('/tracker')
                                // }, 1500)
                            }
                        })
            },
            callback: function(response){
                this.$buefy.toast.open("Successfully paid.")
                console.log(response)

                // clear 
                if (this.getOrder.orderNo) {
                
                    this.$router.push({ name:'tracker', params: { orderNo: this.getOrder.orderNo } })

                }
                //
            },
            close: function(){
                console.log("Payment closed")

            },
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>