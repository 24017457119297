<template>
    <div>
        <div class="p-3">
            <div class="p-3 px-4" >
                <h4 class="title is-3  " >
                    About us
                </h4>
                <p>
                     <span class="has-text-weight-semibold">Shopnscan</span>  is an offers and promo platform that comes with the best prices and most compelling offers. Designed to assist shoppers in locating and discovering nearby Shops and Offers etc.
                </p><br>
                <p>
                    <span class="has-text-weight-semibold">Our referral program</span> provides an opportunity to earn with Shopnscan. Refer to redeem lucrative offers, receive cash or product.
                </p><br>
                <p>
                    <span class="has-text-weight-semibold">Shopnscan </span>is the new way to shop Today with <span class="has-text-weight-semibold">"NO STRESS"</span>, let our platform do the heavy lifting to unlock the best offers and huge discounts.
                </p><br>
                <p>
                    <span class="has-text-weight-semibold">Shop. Scan. Get Rewarded !</span>
                </p><br>
                <p>
                    Shopnscan is the new way to shop !
                </p>
                
                <div style="position: absolute;bottom: 25px;">
                    <p class="is-size-7 has-text-grey mb-3">Shopnscan </p>
                    <p class="is-size-7 has-text-grey"> v1.0.1</p>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'AboutPage',
    }
</script>

<style lang="scss" scoped>

</style>