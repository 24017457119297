import axios from 'axios'
import { store } from '../../store'


export const orders = {
    namespaced: true,
    state: { orders:[], newOrder: null },
    getters: {
          getOrders: (state) => {
                //
                const data = state.orders
                data.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))

                return data
          },
          getOrder: (state) => (orderNo) => {
            //
            const data = state.orders
            const result = data.find((order) => order.orderNo == orderNo)

            return result
          },
          getNewOrder: (state) => {
            return state.newOrder
        }
    },
    actions: {
        async placeOrder({ state,commit }, data ) {
            //
            commit('place', data)

        },
        async getOrders({ state,commit,getters } ) {
            //
            let resp
            let data

            // const user = getters('auth/getUser', { root: true });
            const user = store.getters['auth/getUser']
            const phone = { countryCode: user.phone.substr(0, 4), tel: user.phone.substr(4) }
            //
            if (user) {
                resp = await axios.get('/order/').then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                    // })()
                    commit('updateOrders', data)
                })
                
                // apolloClient.query({ query: GET_ORDER_QUERY, variables: { user: phone } })
                // data = resp.data.filter((order) => order.payment !== null || order.payment.status == "success")
            
            }

            commit('updateOrders', data)
        },
        async clearOrder({ state,commit } ) {
            //
            commit('clear')

        },
    },
    mutations: {
        place: (state, data) => {
            state.newOrder = data
        },
        clear: (state) => {
            state.newOrder = null
        },
        updateOrders: (state, data) => {
            state.orders = data
        },
    }
}