<template>
    <div>
        <div class="columns " >
                <div class="column">
                    <!-- {{ isMounted ? $refs.search2.$el.value : ''}} -->
                    <b-tag type="is-primary" @click="initLocation" class="mx-3 mb-3">Current Location</b-tag>
                    <b-tag type="is-primary" @click="clearSearch" class="ml-0 mb-3 is-pulled-right">Clear</b-tag>
                    <div class="field">
                        <p class="control has-icons-right">
                            <gmap-autocomplete autofocus ref="search2" id="mapautocomplete" class="input" type="search" @place_changed="initMarker" @input="checkVal"></gmap-autocomplete>
                            <!-- <button @click="addLocationMarker">Add</button> -->
                            <a @click="clearSearch" class="icon is-small is-right p-3" style="">
                                <i class="fa fa-times-circle "></i>
                            </a>
                        </p>
                    </div>
                    <div v-if="allAddresses.length > 0 && searchValueEmpty" class="pac-container pac-logo hdpi" style=" position: absolute; left: 24px; top: 162px;max-height: 75vh; overflow-y: auto; width: calc(100% - 48px) ">
                        <div @click="setCachedAddress(address)" v-for="address in allAddresses" :key="address.address" class="columns is-mobile pac-item m-0 py-2 px-4">
                            <span class="active-icon is-flex is-align-items-center column is-1 mr-3 p-2" v-if="address.address == getAddress.address">
                                <b-icon icon="check-circle" type="is-primary"></b-icon>
                            </span>
                            <!-- <span class="pac-icon pac-icon-marker">
                            </span> -->
                            <span class="column p-2">
                                <span class="pac-item-query">
                                {{address.address.split(',')[0]}}
                                </span>
                                <span> {{ (address.address.split(',')[1] ? address.address.split(',')[1] : '')+' '+(address.address.split(',')[2] ? address.address.split(',')[2] : '') }}</span>
                            </span>
                        </div>
                    </div>
                </div>

                
                </div>
                    <!-- <pre> {{ getLoc }} </pre> -->
                <div>
		
            
        </div>

        <!-- <div > -->
                    <!-- v-if="locationMarkers.length > 0" -->
            <gmap-map
                    hidden
                    ref="gmaps"
                    :options="{ styles: styles }"
                    :zoom="16"    
                    :center="center"
                    :disableDefaultUI="true"
                    :gestureHandling="'greedy'"
                    :draggableCursor="true"
                    @change="this.gestureHandling='greedy'"
                    :style="`width:${width};  height: ${height};`"
                    
                    
                >
                
                    <!-- :icon="require('@/assets/green-marker.svg')" -->
                <gmap-marker
                    :icon="{
                                url: require('@/assets/green-marker.svg'), 
                                scaledSize: {width: 45, height: 45, f: 'px', b: 'px'} 
                            }"
                    :key="index"
                    v-for="(m, index) in locationMarkers"
                    :position="m.position"
                    :draggable="false"
                    :clickable="true"
                    :ref="'marker'+index"
                    @click="center=m.position"
                ></gmap-marker>
                </gmap-map>
        <!-- </div> -->
         <!-- {{ address }}
         {{ center }}
         -->
                <!-- {{ locPlaces }}<br><br> -->
                <!-- {{ locationMarkers }}<br><br>  -->
                {{ existingPlace }} 
    </div>
</template>

<script>
    //
    import axios from 'axios'

    export default {
        name: "GoogleMaps",
        data() {
            return {
                searchValueEmpty: true,
                styles: [
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels",
                            "stylers": [
                            {
                                "visibility": "off"
                            }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text",
                            "stylers": [
                            {
                                "visibility": "on"
                            }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [
                            {
                                "visibility": "off"
                            }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.icon",
                            "stylers": [
                            {
                                "visibility": "on"
                            }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels",
                            "stylers": [
                            {
                                "visibility": "off"
                            }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "stylers": [
                            {
                                "visibility": "off"
                            }
                            ]
                        }
                        ],
                address: '',
                home: {
					_geoloc : {
						lat: 5.5912931, lng: -0.2147489
					}
				},
                center: { 
                    lat: 5.5912931,
                    lng: -0.2147489
                },
                locationMarkers: [],
                locPlaces: [],
                existingPlace: null,
                isMounted: false
            }
        },
        props: {
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: '500px'
            },
            type: {
                type: String,
                default: 'address',
            },
        },
        mounted() {
            // console.log(this.$refs.gmaps)
            // this.locateGeoLocation();
            // this.$maps.showMap(this.$refs.map, this.home._geoloc.lat, this.home._geoloc.lng)

            // this.$maps.makeAutoComplete('locationSearch')
            this.$nextTick( ()=>{
                // Dom fully mounted for $refs
               this.isMounted = true;
            })

            //
            // this.initLocation()
            this.locateGeoLocation()
        },
        computed: {
            getLoc() {
                let loc
                navigator.geolocation.getCurrentPosition(res => {
                    loc =  res
                    console.log(res)
                })
                return loc
            },
            allAddresses() {
                return this.$store.getters['offers/allAddresses']
            },
            getAddress() {
                //
                return this.$store.getters['offers/address']
            },
            // checkValue() {
            //     if (!this.isMounted)
            //         return;
                
            //     return this.$refs.search2.$el.value == 0
            // }
            
        },
        methods: {
            clearSearch(){
                
                console.log(document.getElementById("mapautocomplete"))
                document.getElementById("mapautocomplete").value = ''
                this.clearLocationMarker()
                // console.log(this.$refs.mapSearch)
                // this.$refs.mapSearch.value = ''
            },
            checkVal() {
                console.log('search')
                if (this.$refs.search2.$el.value.length > 0){
                    this.searchValueEmpty = false
                } else {
                    this.searchValueEmpty = true
                }
            },
            initMarker(loc) {
                this.existingPlace = loc;
                this.addLocationMarker()
                //
            },
            getLatLngAddress(cords) {
                //
                axios.post('/shop/address', { coordinates: cords })
                    .then( response => {

                        const res = response.data
                        this.$refs.search2.$el.value = res
                        
                        //
                        const marker = {
                            lat: cords.lat,
                            lng: cords.lng
                        };
                        //
                        // const check = this.$store.getters['vendors/getAddress'](this.$refs.search2.$el.value)
                        // if (!check) {
                            // push
                            if (this.type == 'address' && this.$refs.search2.$el.value.length) {
                                this.$store.dispatch('vendors/addPlace', { address: res, location: marker })
                            } else if (this.type == 'delivery' && this.$refs.search2.$el.value.length) {
                                this.$store.dispatch('vendors/addDelivery', { address: res, location: marker })
                            }
                        // }
                })
            },
            addLocationMarker() {
                // console.log(this.$refs.search2.$el.value)
                // console.log(this.existingPlace)

                if (this.existingPlace) {
                    const marker = {
                    lat: this.existingPlace.geometry.location.lat(),
                    lng: this.existingPlace.geometry.location.lng()
                    };
                    this.locationMarkers = [{ position: marker }];
                    this.locPlaces.push(this.existingPlace);
                    this.center = marker;
                    this.existingPlace = null;
                    //
                    // const check = this.$store.getters['vendors/getAddress'](this.$refs.search2.$el.value)
                    // if (!check) {
                        // push
                        if (this.type == 'address' && this.$refs.search2.$el.value.length > 0) {
                            this.$store.dispatch('offers/addPlace', { address: this.$refs.search2.$el.value, location: marker })
                        } else if (this.type == 'delivery' && this.$refs.search2.$el.value.length > 0) {
                            this.$store.dispatch('vendors/addDelivery', { address: this.$refs.search2.$el.value, location: marker })
                        }
                    // }
                    
                }
                //
                // console.log(this.$refs.gmaps)
            },
            clearLocationMarker() {
                console.log(this.$refs.search2.$el.value)
                // console.log(this.existingPlace)

                // if (this.existingPlace) {
                    const marker = {
                    lat: 0,
                    lng: 0
                    };
                    // this.locationMarkers = [{ position: marker }];
                    // this.locPlaces.push(this.existingPlace);
                    this.center = marker;
                    this.existingPlace = null;
                    //
                    // const check = this.$store.getters['vendors/getAddress'](this.$refs.search2.$el.value)
                    // if (!check) {
                        // push
                        if (this.type == 'address') {
                            this.$store.dispatch('offers/addPlace', { address: " ", location: marker })
                        }
                    // }
                    
                // }
                //
                // console.log(this.$refs.gmaps)
            },
            setCachedAddress(address){
                //
                // this.setCachedAddress

                if (typeof(address.location.lat) !== "undefined" || typeof(address.location.lng) !== "undefined"){
                   //
                    this.center = {
                        lat: address.location.lat,
                        lng: address.location.lng
                    };
                    this.existingPlace = {
                        geometry: {
                            location: {
                                lat: function(){ return address.location.lat },
                                lng: function(){ return address.location.lng },
                            }
                        }
                    }

                    this.$refs.search2.$el.value = address.address
                    this.searchValueEmpty = false
                    // console.log('here')
                    this.addLocationMarker()
                    
                }

            },
            locateGeoLocation: function() {

                //
                navigator.permissions.query({ name: 'geolocation' })
                .then(res=> {
                    console.log(res)
                    if (res.state !== 'denied'){
                        //
                        navigator.geolocation.getCurrentPosition(res => {
                            this.center = {
                            lat: res.coords.latitude,
                            lng: res.coords.longitude
                            };
                            this.existingPlace = {
                                geometry: {
                                    location: {
                                        lat: function(){ return res.coords.latitude },
                                        lng: function(){ return res.coords.longitude },
                                    }
                                }
                            }
                            //
                            this.searchValueEmpty = false
                            // get address from coords
                            this.getLatLngAddress(this.center)

                            // console.log(this.$refs.search2)

                            this.addLocationMarker()
                        });
                    } else {
                        //
                        let add
                        if (this.type == 'address') {
                            add = this.$store.getters['offers/address'];
                        }
                        else if (this.type == 'delivery') {
                            add = this.$store.getters['offers/delivery'];
                        }  

                        if (typeof(add.location.lat) !== "undefined" || typeof(add.location.lng) !== "undefined"){
                            //
                            this.center = {
                            lat: add.location.lat,
                            lng: add.location.lng
                            };
                            this.existingPlace = {
                                geometry: {
                                    location: {
                                        lat: function(){ return add.location.lat },
                                        lng: function(){ return add.location.lng },
                                    }
                                }
                            }

                            
                            this.searchValueEmpty = false
                            //
                            this.$refs.search2.$el.value = add.address
                            // console.log('here')
                            this.addLocationMarker()
                        }
                    }
                })
                
            },
            initLocation() {
                    navigator.geolocation.getCurrentPosition(
                    (res)=>{
                        console.log(res)
                        this.locateGeoLocation()
                    },
                    this.handleLocationError
                );
            },

            handleLocationError(error) {
                // this.$buefy.toast.open(`Unable to retrieve location: ${error.message}`);
                this.$buefy.toast.open({ message: `Unable to fetch location: kindly turn on device location.`, duration: 3500 });
                this.locateGeoLocation()
            },
            changed(event) {
                // const place = event.detail;

                // if (!place.geometry) return

                // this.$router.push({
                //     name: 'search',
                //     query: {
                //         lat: place.geometry.location.lat(),
                //         lng: place.geometry.location.lng(),
                //         label: this.$refs.locationSearch.value,
                //     }
                // })

                // this.home._geoloc.lat = place.geometry.location.lat();
                // this.home._geoloc.lng = place.geometry.location.lng();

                // this.$maps.showMap(this.$refs.map, this.home._geoloc.lat, this.home._geoloc.lng)


            }
        }
    }
</script>

<style lang="scss" >
#mapautocomplete::-webkit-search-cancel-button{
    position:absolute;
    right:20px; 
}

.active-icon {
    // width: 15px;
    // height: 20px;
    // margin-right: 7px;
    // margin-top: 6px;
    // display: inline-block;
    // vertical-align: middle;
    // -webkit-background-size: 34px 34px;
    // -moz-background-size: 34px;
    // background-size: 34px;
}

.gmnoprint,.gm-fullscreen-control {
    display: none ;
}
</style>