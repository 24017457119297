<template>
    <div>
        <div class="p-5">
            <!-- <icon
                type="chevron-back"
                class="m-4"
                
                style="top:0;position:absolute"
            ></icon> -->
            <b-switch v-if="preferences.length > 0" style="float:right" class="is-flex is-justify-content-center p-2" size="" v-model="isTab">
                    <span class="small"></span> 
                    
                </b-switch>

                <span v-if="cartItems > 0" @click="$router.push('cart')" class="is-flex is-pulled-right title pt-1 is-5"> <feather  type="shopping-bag" class=" mx-2 dark" ></feather> <b-tag style="height: 1.5em; padding-left: 0.5em;padding-right: 0.5em;margin-left: -18px;margin-top:-5px" type="is-primary">{{ cartItems }}</b-tag></span>

            <feather size="30" type="arrow-left" @click="goBack" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 pl-5">{{ currentShop.name }}</h4>
            <p class="subtitle is-4 ml-5 pl-5  pt-2 small">{{ currentShop.locationName }} <b-tag v-if="!profiled" class="ml-1" type="is-primary" rounded @click="setProf()">Setup Profile</b-tag> </p>
            <!-- <h4 class="title is-4 m-3 pb-0 mb-0">Shopname</h4> -->
            
            <div>
                    <div class="" style="max-width:460px;">
                        <b-field label="">
                            <b-input @input.native="onSearch" @keyup.native="load" :loading="loading" v-model="search" placeholder="Search product"></b-input>
                        </b-field>
                    </div>
            </div>

            <div class="pt-2">
                
                
                <!-- <div class="py-3" style="max-width:460px;margin:auto">
                        <b-field label="">
                            <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" v-model="searchString" placeholder="Search products "></b-input>
                        </b-field>
                </div> -->
                <ProductTemplate :products="data" :currency="currentShop.country.currency" v-if="data.length > 0" :categoryView="false"/>
                
                <ProductTemplate  :products="allProducts" :currency="currentShop.country.currency" v-if="allProducts.length > 0 && data.length == 0 && !isTab" />
                <b-tabs position="is-centered" v-if="preferences.length > 0 && isTab && data.length == 0" >
                    <br>
                    <b-tab-item v-for="cat in preferences" :key="cat" :label="cat">
                        <template #header>
                            <span> {{ cat }} <b-tag rounded> {{ allProducts.filter((pro)=>pro.category == cat).length }} </b-tag> </span>
                        </template>
                        <!--  -->
                        <ProductTemplate 
                            v-if="(allProducts.filter( (pro) => pro.category == cat )).length > 0 && data.length == 0" 
                            :products="allProducts.filter((pro)=>pro.category == cat)" 
                            :currency="currentShop.country.currency" 
                            :categoryView="false" />
                    </b-tab-item>
                </b-tabs>
                <div class="py-5">

                </div>
                <!-- <ProductSkeleton v-else/> -->
            </div>
            
            
            
        </div>
        <b-loading v-model="isLoading" :can-cancel="true" :is-full-page="isFullPage"></b-loading>
        <MainFooter />
        
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ProductTemplate from '../components/ProductTemplate'
    import MainFooter from '../components/footer.vue'
    import axios from 'axios'

    export default {
        name: 'mapView',
        data() {
            return {
                isLoading: true,
                isFullPage: true,
                isTab: false,
                currentShop: {
                    name:'',
                    locationName:'',
                    categories: []
                },
                data: [],
                // allProducts: [],
                searchString: '',
                addressChanged: false,
                loading: false,
                prevRoute: null,
                search: '',
                currency: '',
                preferences: [],
                profiled: true
            }
        },
        components: {
            ProductTemplate,
            MainFooter
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevPath() { return this.prevRoute ? this.prevRoute : '/' },
            
            allProducts() {
                // return this.preferences.length > 0 ? this.$store.getters['products/getPrefProduct'](this.preferences) : this.$store.getters['products/getProduct']
                return this.profiled ? this.$store.getters['products/getPrefProduct'](this.preferences) : this.$store.getters['products/getProduct']
            }, 
            getCategories() {
                return this.currentShop.categories
            },
            cartItems() {
                return this.$store.getters['cart/cartCount']
            }
        },
        watch: {
            search() {
                if (this.searchString.length == 0) {
                    this.data = []
                }
            },
        },
        mounted() {

            // console.log(this.$route.params)

            if (Object.keys(this.$route.query).length !== 0) {

                //
                axios.get('/shop?shop='+this.$route.query.shop).then((res)=>{
                    //
                    this.currentShop = res.data[0];

                    this.isLoading = false

                    this.checkShop();

                    this.getProducts()
                    // this.checkVisit()
                })

                // this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"shop" } ).then(()=>{
                //     //
                //     console.log(this.$store.getters['shops/getShops'][0])
                //     //
                //     this.currentShop = this.$store.getters['shops/getShops'][0]
                // })
            }
            
            else if (Object.keys(this.$route.params).length !== 0 && typeof(this.$route.params.shopName) !== "undefined" ) {

                //
                axios.get('/shop?shop='+this.$route.params.shopName).then((res)=>{
                    //
                    this.currentShop = res.data[0];

                    this.isLoading = false

                    this.checkShop()
                    
                    this.getProducts()
                })

                // this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"shop" } ).then(()=>{
                //     //
                //     console.log(this.$store.getters['shops/getShops'][0])
                //     //
                //     this.currentShop = this.$store.getters['shops/getShops'][0]
                // })
            }
            
            else if (Object.keys(this.$route.params).length !== 0) {

                
                this.checkShop();
                
                const data = {
                        link: 'products',
                        data: this.$route.params
                    }

                this.currentShop.id = this.$route.params.id
                this.currentShop.name = this.$route.params.name
                this.currentShop.cover = this.$route.params.cover
                this.currentShop.phone = this.$route.params.phone
                this.currentShop.country = this.$route.params.country
                this.currentShop.whatsapp = this.$route.params.whatsapp
                this.currentShop.logo = this.$route.params.logo
                this.currentShop.locationName = this.$route.params.locationName
                this.currentShop.location = this.$route.params.location
                this.currentShop.rate = this.$route.params.rate
                this.currentShop.type = this.$route.params.type
                this.currentShop.website = this.$route.params.website
                this.currentShop.categories = this.$route.params.categories
                this.currentShop.available = this.$route.params.available
                this.currentShop.active = this.$route.params.active

                this.$store.dispatch('pages/setPage', data)

                this.getProducts()

                this.isLoading = false
                // this.checkVisit()
            }
            else {
                
                this.checkShop();

                const data = this.$store.getters['pages/getPage']('products')

                if (data !== null) {
                        // this.form = data.search
                        this.currentShop.id = data.id
                        this.currentShop.name = data.name
                        this.currentShop.cover = data.cover
                        this.currentShop.phone = data.phone
                        this.currentShop.country = data.country
                        this.currentShop.whatsapp = data.whatsapp
                        this.currentShop.logo = data.logo
                        this.currentShop.locationName = data.locationName
                        this.currentShop.location = data.location
                        this.currentShop.rate = data.rate
                        this.currentShop.type = data.type
                        this.currentShop.website = data.website
                        this.currentShop.categories = data.categories
                        this.currentShop.available = data.available
                        this.currentShop.active = data.active
                }

                this.getProducts()

                this.isLoading = false
                // this.checkVisit()
            }
        },
        methods: {
            setProf() {
                this.$buefy.dialog.confirm({
                        title:'Shop Profile',
                        message: `Set your profile with <b>${this.currentShop.name}</b> for a customized shopping experience!`,
                        confirmText: 'Set Profile',
                        onConfirm: () =>{
                            this.gotoPref()
                        }
                    })

                    //
                    
            },
            checkShop() {
                //
                axios.get('/user/pref/'+this.currentShop.name).then(response=>{
                    //
                    const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    this.preferences = shop.preferences
                    this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.profiled = false
                    console.log(error)
                })
            },
            getProducts() {
                //
                this.$store.dispatch('products/getProducts', this.currentShop.name)
                //
                // axios.get('/products/'+this.currentShop.name).then(response=>{
                //     //
                //     this.allProducts = response.data.filter(p=> this.preferences.includes(p.category))

                //     if (response.data.filter(p=> this.preferences.includes(p.category)).length == 0) {
                //         this.$buefy.toast.open({ message: "There no products for your prefernce", duration: 2000 })
                //     }

                // }).catch(error=>{
                //     //
                //     this.$buefy.toast.open("Oops failed.")
                // })
            },
            gotoPref() {
                //
                this.$router.push({ 
                                        name: 'setuppref',
                                        params: { 
                                            editPref: false, 
                                            shop: this.currentShop, prefs: false 
                                        },
                                        
                                  })
            },
            getShops() {
                this.$store.dispatch('shops/getAllShops')
            },
            redirect() {
                this.$router.back()
                // this.$router.push('home')
            },
            onSubmit() {
                if (this.searchString.length >= 3) {
                    // console.log(this.searchString)
                }
            },
            goBack() {
                this.$router.back()
            },
            async onSearch() {
                // console.log('here')
                if (this.search.length >= 3) {
                    
                   
                    const result = this.preferences.length > 0 ? await this.$store.getters['products/searchPrefProduct'](this.preferences,this.search) : await this.$store.getters['products/searchProduct'](this.search)

                    console.log(result)

                    this.data = result
                    
                }
            }, 
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .dark {
        color: $dark;
    }
</style>