<template>
    <div>
            <div class="py-4">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="shop in shops" :key="shop.id" class="column is-mobile is-half-tablet is-one-third-desktop">

                        <a class="columns  is-mobile mb-0" >
                            <div @click="routeTo(shop)" class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                                <b-image :src="shop.logo"></b-image>
                            </div>
                            <div @click="routeTo(shop)" class="column">
                                <p class="mb-2">{{ shop.name }}</p>
                                <b-field>
                                    <b-tag class="mb-1 has-text-primary">{{ shop.type.length > 25 ? shop.type.substr(0, 25)+' ...' : shop.type }}</b-tag>   
                                    <b-rate
                                        class="ml-2"
                                        :value="shop.rate"
                                        :max="1"
                                        :size="'is-small'"
                                        :show-score="true"
                                        :disabled="true">
                                    </b-rate>
                                    <!-- <br> -->
                                </b-field>
                                <span class="has-text-grey small "> {{ shop.locationName }} </span>
                            </div>
                            <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                                <!-- <a class="p-3" :href="`tel:${shop.phone.countryCode+shop.phone.tel}`">
                                    <feather size="20" class="has-text-" type="shopping-cart"></feather>
                                </a> -->
                                <a class="p-3" @click="routeTo(shop)">
                                    <feather size="20" class="has-text-" type="shopping-cart"></feather>
                                </a>
                                <a class="p-3" @click="routeToProduct(shop)">
                                    <feather size="20" class="has-text-" type="coffee"></feather>
                                </a>
                            </div>
                        </a>

                        <hr class="m-0 p-0 mb-0">
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"

    export default {
        name: 'ShopTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            shops: {
                required: true
            },
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            routeTo(data) {

                //
                
                    //
                    this.$router.push({ name: 'shop', 
                                    params: { 
                                        id: data.id,
                                        name: data.name,
                                        cover: data.cover,
                                        phone: data.phone,
                                        country: data.country,
                                        whatsapp: data.whatsapp,
                                        logo: data.logo,
                                        locationName: data.locationName,
                                        location: data.location,
                                        rate: data.rate,
                                        type: data.type,
                                        website: data.website,
                                        categories: data.categories,
                                        available: data.available,
                                        active: data.active,
                                    } 
                                })
                

            },
            routeToProduct(data) {

                //
                
                    //
                    this.$router.push({ name: 'products', 
                                    params: { 
                                        id: data.id,
                                        name: data.name,
                                        cover: data.cover,
                                        phone: data.phone,
                                        country: data.country,
                                        whatsapp: data.whatsapp,
                                        logo: data.logo,
                                        locationName: data.locationName,
                                        location: data.location,
                                        rate: data.rate,
                                        type: data.type,
                                        website: data.website,
                                        categories: data.categories,
                                        available: data.available,
                                        active: data.active,
                                    } 
                                })
                

            },
            markAsRead(id) {
                // console.log(this.$store)
                this.$buefy.toast.open("Marked as read!");
                
                return this.$store.getters['cart/alreadyInCart'](id)
            },
        } 
    }
</script>

<style lang="scss" scoped>// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .list-title {
        font-size: 0.95rem;
    }

    .columnxs {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }

    .menu-list > a {
        padding: 1rem 0.75rem !important;
    }
</style>