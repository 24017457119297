<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav hidden />
        </div>
        <!-- {{ vendors }} -->
        
        <div class="p-5" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <!-- <VendorSkeleton/> -->



            <div class="pb-2 m-3" style="z-index: 100;position: relative;">
                <div style="float:right" class="is-justify-content-center">
                    <feather  @click="$router.push('scan')" type="aperture" size="29" :class="`px-2 py-4 mr-0 is-flex ${getUser.thereturn ? 'has-text-white' : 'has-text-dark'}`"></feather>
                    <!-- <h6 class="small">Profile</h6> -->
                </div>
                
                <h1 :class="`title is-2 `">Shopnscan<span style="font-weight:300;">.</span> </h1>
                <p :class="`subtitle is-6  `">your shop companion</p>
            </div>

            <div hidden>
                <router-link to="rateshop">rate shop</router-link><br>
                <router-link to="setuppref">setup pref</router-link>
            </div>

            <!-- <figure class="image is-16by9">
                <img src="https://bulma.io/images/placeholders/640x360.png">
            </figure> -->

            <b-carousel :autoplay="true" :indicator-inside="false">
                <b-carousel-item v-for="(carousel, i) in sliders" @click="render(carousel)" :key="i">
                    <section :class="`hero is-medium slider-image`">
                    <!-- <section :class="`hero is-medium slider-image`" :style="`background-image: url(${carousel.img})`"> -->
                        <b-image 
                            :ratio="`2by1` " 
                            class="image" 
                            :lazy="true"
                            :src="carousel.img"
                        ></b-image>
                        <!-- <div class="hero-body has-text-centered">
                            <h4 class="title is-4 white">{{carousel.text}}</h4>
                        </div> -->
                    </section>
                </b-carousel-item>
            </b-carousel>

            <p class="px-3 pt-3 pb-0 has-text-weight-bold">Statistics</p>
            <!-- <div class="card"> -->
                <!-- <div class="card-content"> -->
                    <div class="content p-2 text-center my-4">
                        <div class="columns is-mobile is-multiline">
                            
                            <div @click="goto('myshops')" class="column card p-4 m-1">
                                <h4 class="title is-4"> {{ kmForm(shops) }}</h4>
                                <p class="subtitle has-text-grey">Shops</p>
                            </div>
                            <div @click="goto('orders')" class="column card p-4 m-1">
                                <h4 class="title is-4"> {{ (getUser.phone.includes('+233') ? '₵' : '$')+kmForm(orders) }}</h4>
                                <p class="subtitle has-text-grey">Orders </p>
                            </div>
                            <div @click="getInfo()" class="column  card p-4 m-1">
                                <!-- <b-tooltip label=""
                                    type="is-light"
                                    position="is-top">
                                    <b-button label="Light" />
                                </b-tooltip> -->
                                <h4 class="title is-4">{{ kmForm(points) }}</h4>
                                <p class="subtitle has-text-grey">Points</p>
                            </div>
                            <!-- <div class="column card p-4 m-1">
                                <h4 class="title is-3">14</h4>
                                <p class="subtitle has-text-grey">Orders</p>
                            </div> -->
                        </div>
                    </div>
                <!-- </div> -->
            <!-- </div> -->
            <p class="px-3 pt-3 pb-0 has-text-weight-bold">Featured</p>
            <!-- {{ getFeatured[0]  }} -->
            <OfferHomeTemplate :currency="'GHC '" :classes="'columns cov is-mobile'" :offers="getFeatured" ></OfferHomeTemplate>
            
            <p class="px-3 pt-1 pb-0 has-text-weight-bold">Trending</p>
            <OfferHomeTemplate :currency="'GHC '" :classes="'columns cov is-mobile'" :offers="getTrending" ></OfferHomeTemplate>


            <!-- <p class="px-3 pt-1 pb-0 has-text-weight-bold">Best Sellers</p> -->


            <p @click="$router.push('alerts')" class="px-3 p-3 has-text-weight-bold"> Alerts
                <!-- <b-tag type="is-dark"> 5</b-tag> -->
            </p> 
            <AlertTemplate :alerts="alerts" />
            <div v-if="false" class="contain p-3 mb-3">
                <a class="columns p-1 card is-mobile mb-5">
                    <!-- <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center"> -->
                        <!-- <div class=" bg-grey is-flex is-justify-content-center is-align-items-center" style="width: 10vw;height:  10vw; border-radius: 100%">
                            <feather size="18" class="has-text-primary" type="bell"></feather>
                        </div> -->
                        <!-- <b-button  type="is-primary" icon-left="bell"></b-button> -->
                        <!--  -->
                        <!-- <p> Apr 12</p> -->
                        <!-- <span class="small has-text-grey">12:00</span> -->
                            <!-- <feather size="26" class="has-text-primary" type="bell"></feather> -->
                    <!-- </div> -->
                    <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                        <feather size="22" class="has-text-primary" type="bell"></feather>
                        <b-button  type="is-primary" icon-left="bell"></b-button>
                    </div>
                    <div class="column">
                        <p class="list-title">Helloo kindly check out</p>
                        <span class="has-text-grey small ">Apr 12 </span>
                    </div>
                    <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                        <feather size="20" class="has-text-primary" type="check-square"></feather>
                    </div>
                </a>
            </div>

            
            <div class="space my-4 "></div>
            
        </div>
        <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
       
        <MainFooter />

    </div>
</template>

<script>

    import { mapGetters, mapActions} from 'vuex'
    import { store } from '@/store'
    import router from '@/router'
    
    import axios from 'axios'
    import SideNav from '../components/sidenav'
    // import VendorSkeleton from '../components/VendorSkeleton'
    import AlertTemplate from '../components/AlertTemplate.vue'
    import OfferHomeTemplate from '../components/OfferHomeTemplate'
    
    import MainFooter from '../components/footer'

    export default {
        name: 'IndexPage',
        data(){
            return {
                shops: 0,
                visits: 0,
                orders: 0,
                points: 0,
                visitRates: 0,
                sliders: [],
                carousels: [
                    // { text: 'Slide 1', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'visits' },
                    // { text: 'Slide 2', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'profile' },
                    // { text: 'Slide 3', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'myshops' },
                    // { text: 'Slide 4', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'mapview' },
                    { text: 'Slide 1', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1690889676/shopnsave/sliders/shoprite1_htb1eb.jpg', link: 'shop', name: 'Shoprite - Accra Mall' },
                    { text: 'Slide 2', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1690889676/shopnsave/sliders/mrp_slide_lkcyhb.jpg', link: 'shop', name: 'MRP Accra Mall' },
                    { text: 'Slide 3', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1690889674/shopnsave/sliders/kimo_slide_l9d1z2.jpg', link: 'shop', name: 'Kimo Home Spintex' },
                    // { text: 'Slide 2', img: require('@/assets/img/slider/5.jpg') }
                    
                ],
                screenWidth: 0,
                lastPath: null,
                vendors: { },
                isLoading: false,
                alerts_: [
                    {
                        id: 1,
                        message: "Welcome to shopnscan",
                        date: "Apr 24",
                        icon: "bell",
                        link: "",
                        read: true,
                    },
                    {
                        id: 2,
                        message: "Hurrey you've reviewed Woodin - Accra Mall",
                        date: "Apr 24",
                        icon: "cart",
                        link: "",
                        read: false,
                    },
                    {
                        id: 3,
                        message: "Hello check out your points",
                        date: "Apr 24",
                        icon: "certificate",
                        link: "",
                        read: false,
                    },
                    {
                        id: 4,
                        message: "Visit any partner shops to redeem 10 points.",
                        date: "Apr 24",
                        icon: "cart",
                        link: "",
                        read: false,
                    },
                ]
            }
        },
        components: {
            SideNav,
            // VendorSkeleton,
            AlertTemplate,
            MainFooter,
            OfferHomeTemplate
        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.$params,
            async () => {
                // this.$store.dispatch('vendors/getBusiness')
                
                // mapActions('vendors',['getBusiness'])
                // this.getVendors()

            },
            { immediate: true }

            )

        },
        watch: {
            alerts() {
                // if (this.alerts.length > 0) {
                //     // push only 10
                //     const al = this.alerts.filter((a)=>a.read == false).splice(0,10)
                    
                //     for (let index = 0; index < al.length; index++) {
                //         const alert = al[index];
                        
                //         this.$notification.show('Shopnscan', {
                //             body: alert.message
                //         }, {
                //             onclick: function() {
                //                 //
                //                 axios.patch('/alert/read', { id: alert._id, read: alert.read == false ? true : false }).then(response=>{
                //                     //
                //                     if (response) {
                                        
                //                         console.log(`Marked as ${alert.read == false ? "read!" : "unread!"}`)

                //                         if (alert.link) {
                //                             //
                //                             router.push(alert.link)
                //                         }

                //                         //
                //                         store.dispatch("alert/getAlert");
                //                     }
                //                 }).catch(error=>{
                //                     console.log(error.response.data)
                //                 })
                //             },
                //             onshow: function() {
                //                 // this.$router.push('alerts')
                //                 console.log('notification onshow')
                //             }
                //         })
                //     }
                // }
            }
        },
        mounted(){
            this.updateScreenWidth();
            this.onScreenResize();

            this.queryChecker()

            this.checkShops()
            this.checkOrders()
            this.getShops()
            this.getSliders()
            this.getHomeOffers()

            // if (!this.address.address) {
            //     //
            //     this.$buefy.toast.open('Kindly set delivery address')
            //     this.$router.push('/mapview')
            // }
        },
        computed: {
            alerts() {
                return this.$store.getters['alert/getAlerts']
            },
            getUser() {
                return this.$store.getters['auth/getUser']
            },
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            // ...mapGetters('vendors', ['discountedMenu']),
            // ...mapGetters('vendors', ['freeDelivery']),
            // ...mapGetters('vendors', ['mostRated']),
            // ...mapGetters('vendors', ['newBusiness']),
            // ...mapGetters('vendors', ['allBusiness']),
            // ...mapGetters('vendors', ['address'])
            ...mapGetters('offers', ['getFeatured']),
            ...mapGetters('offers', ['getTrending'])
        },
        methods: {
            getInfo(){
                // this.$buefy.toast.open()
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Refer friends / make orders to build Points for exclusive Offers / Products.',
                    pauseOnHover: true
                })
            },
            render(v) {

                if (v.shop) {
                    //
                    this.postClick(v)

                    if (v.params) {
                        
                        this.$router.push({ name: v.link, params: { search: v.params, shop: v.shop } })

                    } else {
                        
                        this.$router.push({ name: v.link, query: { shop: v.shop } })
                        
                    }
                }
                // console.log(v)
            },
            queryChecker() {
                const data = this.$route.query
                const saved = localStorage.getItem('shop-link') ? JSON.parse(localStorage.getItem('shop-link')) : false
                
                if (Object.keys(data).length !== 0) {
                    //
                    localStorage.setItem("shop-link", JSON.stringify(data) )     

                    // prepare re-router
                    if ((this.$route.query.shop || this.$route.query.offers || this.$route.query.receipt ) && this.$route.query.secured) {
                        // set loader
                        this.isLoading = true
                        //
                        if (this.$store.getters['shops/verifyShop'](this.$route.query.shop, this.$route.query.secured)) {
                            //
                            this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"link" } ).then(()=>{
                                this.$router.push('setuppref')
                            })

                        } else if (this.$store.getters['shops/verifyShop'](this.$route.query.offers, this.$route.query.secured)) {
                            //
                            this.$store.dispatch('shops/getShop', { shop: this.$route.query.offers, type:"link" } ).then(()=>{
                                this.$router.push({ name: 'products', params: this.$store.getters['shops/getShops'][0] })
                            })

                        } else if (this.$store.getters['shops/verifyShop'](this.$route.query.receipt, this.$route.query.secured)) {
                            //
                            this.$store.dispatch('shops/getShop', { shop: this.$route.query.receipt, type:"link" } ).then(()=>{
                                this.$router.push('rateshop')
                            })

                        } else {
                            //
                            localStorage.removeItem('shop-link')
                        }
                    }
                } 
                
                else if (saved) {
                    // prepare re-router
                    if (saved.shop && saved.secured) {
                        //
                        if (this.$store.getters['shops/verifyShop'](saved.shop, saved.secured)) {
                            //
                            this.$store.dispatch('shops/getShop', { shop: saved.shop, type:"link" } )
                            //
                            this.$router.push('setuppref')
                            
                        } else {
                            //
                            localStorage.removeItem('shop-link')
                        }
                    }
                }
                
            },
            getImgUrl(value) {
                return `/assets/img/slider/${value}`
            },
            kmForm(num) {
                return Math.abs(num) > 999999 ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'm' : Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : (Math.sign(num)*Math.abs(num))
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            getImage(img) {
                //
                const link = `http://localhost:4000/api/images/slider/${this.$store.getters['auth/getUser'].token}/*/${img}`
                // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
                return link
            },
            async getVendors() {

                this.isLoading = true

                await this.$store.dispatch('vendors/getBusiness').then(()=>{
                    this.isLoading = false
                })

            },
            getHomeOffers() {

                this.$store.dispatch('offers/getHomeOffers')

            },
            checkShops() {
                //
                axios.get('/user/pref').then(response=>{
                    //
                    this.shops = response.data.shops.length

                    this.points = response.data.points ? response.data.points.reduce((a, b) => a + b.point, 0) : 0                

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            postClick(data) {
                //
                axios.post('/slider/click', { slider: data.shop, user: {
                    countryCode: this.$store.getters['auth/getUser'].phone.substring(0,4),
                    tel: this.$store.getters['auth/getUser'].phone.substring(4)
                } } ).then(response=>{
                    //
                    console.log(response.data)              

                }).catch(error=>{
                    //
                    console.log(error.response.data)
                })
            },
            getShops() {
                this.$store.dispatch('alert/getAlert')
            },
            checkAlerts() {
                //
                axios.get('/alert').then(response=>{
                    //
                    this.alerts = response.data            

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            checkVisits() {
                //
                axios.get('/shop/visit').then(response=>{
                    //
                    this.visits = response.data.length
                    // this.visitRates = response.data.reduce((a, b) => a + b.rate, 0) !== 0 ? response.data.reduce((a, b) => a + b.rate, 0) / this.visits : 0

                    
                    // const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    // this.preferences = shop.preferences
                    // this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            checkOrders() {
                //
                axios.get('/order').then(response=>{
                    //
                    // this.visits = response.data.length
                    this.orders = response.data.filter(o => o.status == 'delivered').reduce((a, b) => a + b.totalPrice, 0)

                    
                    // const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    // this.preferences = shop.preferences
                    // this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            getSliders() {
                //
                axios.get('/user/sliders').then(response=>{
                    //
                    const data = response.data
                    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt) )

                    this.sliders = data
                    // check 
                    

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            goto(link) {
                this.$router.push(link)
            },
            setAddress(loc) {
                //
                this.$store.dispatch('vendors/addPlace', { address: loc.address, location: loc.location })
                //
                this.getVendors()
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .white {
        color: $white !important;
    }

    .carousel-items {
        border-radius: 1rem;
    }
    .slider-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    .space {
        padding: 5px;
    }

    .cov {
        overflow-x: scroll;
        -ms-overflow-style: none;
        -moz-overflow-style: none;
        scrollbar-width: none;
    }

    .cov::-webkit-scrollbar {
    display: none;
    }

    .cov .media-content {
        overflow: hidden;
    }

    .card-content  {
        padding: 1rem 1.25rem  !important;
    }

    .media-content .b-skeleton {
        margin:  0 !important;
    }
</style>