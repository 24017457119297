import { render, staticRenderFns } from "./PayStack.vue?vue&type=template&id=1f8559be&scoped=true"
import script from "./PayStack.vue?vue&type=script&lang=js"
export * from "./PayStack.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f8559be",
  null
  
)

export default component.exports