<template>
    <div>
            <!-- <h4 class="title is-4">Profile</h4> -->
            <div class="p-5">
                <div class="p-3 px-4" >
                    <h3 class="title is-4 "  v-if="getUser">
                        {{ getUser.firstname+' '+getUser.lastname }}
                    </h3>
                    <h6  v-if="getUser"  class="subtitle is-5 mb-3">{{ getUser.phone }}</h6>
                    <b-tag v-if="getUser && getUser.email" round type="is-primary" class="is-size-7">{{ getUser.email }}</b-tag>
                    <hr>
                    <b-menu>
                    <b-menu-list label="">
                        <b-menu-item xicon="cart" label="Cart" tag="router-link" to="/cart" >
                        </b-menu-item> 
                        <b-menu-item xicon="account-outline" label="Personal" tag="router-link" to="/user" > 
                        </b-menu-item>
                        <!-- <b-menu-item xicon="layers" tag="router-link" to="visits" label="My Visits"  ></b-menu-item> -->
                        <b-menu-item xicon="cart" tag="router-link" to="myshops" label="My shops Profile"  ></b-menu-item>
                        <b-menu-item xicon="account-multiple-outline" @click="refer" label="Refer your friend"  ></b-menu-item>
                        <b-menu-item xicon="bell-outline" label="Alerts" tag="router-link" to="alerts" ></b-menu-item>
                        <b-menu-item xicon="help-circle-outline" label="Contact " tag="router-link" to="contact" ></b-menu-item>
                        <b-menu-item xicon="information-outline" label="About " tag="router-link" to="about" ></b-menu-item>
                        
                    </b-menu-list>
                    <hr class="m-2">
                    <b-menu-list label="">
                        <!-- <b-menu-item label="Account" tag="router-link" to="/account" ></b-menu-item> -->
                        <b-menu-item xicon="logout" @click.native="logOut" label="Sign out" tag="a" ></b-menu-item>
                    </b-menu-list>

                    <b-menu-list label="">
                        <!-- <span class="px-2 py-4" style="position:absolute;bottom:0">&copy;  myHealthSusu. </span> -->
                    </b-menu-list>
                    </b-menu>
                </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"
    import { userService } from '../services';
    import MainFooter from '../components/footer'

    export default {
        name: "ProfilePage",
        data() {
            return {
                user: ''
            }
        },
        components: {
            
            MainFooter
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
        },
        methods: {
            logOut() {
                this.open = false;
                //
                userService.logout()
                //
                this.$router.push('/signin')
            },
            refer() {
                this.$buefy.dialog.confirm({
                    message: '<b>Refer your Friend</b><br><br>Receive <b>10 Points</b> for every referral. Your referred friends will enjoy <b>5 Points</b> on signup and  <b>10 Points</b> on order placement. Use points to <b>Redeem Gifts, Discounts & Special Offers</b>!',
                    confirmText: 'Copy link',
                    cancelText: 'Cancel',
                    onConfirm: () => {
                        this.$buefy.toast.open('User agreed')

                        this.$copyText(`https://app.shopnscan.app/refer/${this.$store.getters['auth/getUser'].firstname+' '+this.$store.getters['auth/getUser'].lastname}|${(this.$store.getters['shops/generateShopHash'](this.$store.getters['auth/getUser'].firstname+' '+this.$store.getters['auth/getUser'].lastname))}`).then( (e)=>{
                            this.$buefy.toast.open(`Refer link copied!`)
                        }, (e)=> {
                            this.$buefy.toast.open(`Failed to copy Refer link!`)
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>