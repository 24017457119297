<template>
    <div>
        <div class="p-5">
            <!-- <icon
                type="chevron-back"
                class="m-4"
                tag="router-link"
                to="/"
                style="top:0;position:absolute"
            ></icon> -->
            <!-- <feather tag="router-link" size="30" to="/" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather> -->
            <!-- <h4 class="title is-4 ml-5 pl-5">Enter address</h4> -->
            <span v-if="cartItems > 0" @click="$router.push('cart')" class="is-flex is-pulled-right  pt-3 is-5"> <feather  type="shopping-bag" class=" mx-2 dark" ></feather> <b-tag style="height: 1.5em; padding-left: 0.5em;padding-right: 0.5em;margin-left: -18px;margin-top:-5px; " type="is-primary">{{ cartItems }}</b-tag></span>

            <h4 class="title is-4 m-3 pb-0 mb-0">Offers <span class="small has-text-primary"> {{ (Object.keys($route.query).length !== 0) ? ': '+$route.query.shop : (Object.keys($route.params).length !== 0) ? ': '+$route.params.shop : '' }}</span> </h4>

            <!-- <span v-if="cartItems > 0" @click="$router.push('cart')" class="is-pulled-right title is-5"> Cart <b-tag type="is-primary">{{ cartItems }}</b-tag></span> -->

            <!-- <b-tag v-else type="is-primary" @click="getAllOffers" class="mx-3 mb-3 is-pulled-right">All Offers</b-tag> -->
            
            <GoogleMaps2 :height="'calc(100vh - 175px)'" style=""/>

            <div>
                    <div class="" style="max-width:460px;">
                        <b-field label="">
                            <b-input icon-right="magnify" icon-right-clickable @icon-right-click="onSearch" @keyup.native.enter="onSearch" :loading="loading" v-model="search" placeholder="Enter anything & tap search"></b-input>
                        </b-field>
                    </div>
            </div>
            
            <OfferTemplate :currency="'GHC '" v-if="data.length > 0" :offers="data" ></OfferTemplate>
            <OfferTemplate :currency="'GHC '" v-if="allOffers.length > 0 && data.length == 0" :offers="allOffers" ></OfferTemplate>
            
            <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

            <div v-else-if="!isLoading && allOffers.length == 0" class="p-2">
                <div class="text-center p-4" style="max-width:300px;margin:auto">
                    
                    <!-- <h4 class="title is-5  "> !Oops, sorry we don't deliver here </h4> -->
                    <p class="has-text-weight-medium has-text-grey subtitle">We are currently in. </p>
                </div>
                <hr class="m-2">

                <ul v-if="mapLocations.length !== 0"  class="menu-list columns is-multiline is-mobile pt-1 px-1" style="">
                    <li v-for="loc in mapLocations" :key="loc.address" class="column is-half-mobile is-one-third-tablet is-one-fourth-desktop p-1">
                        <a @click="setAddress(loc)" class="my-1">
                            <p class="title is-6 is-flex is-align-items-center" style="font-weight: 400">
                            <feather type="map-pin" class="pr-3"> </feather>
                                    {{ loc.address.split(',')[0] }}</p>
                                    <p class="subtitle is-6 ml-5 pl-3">{{ loc.address.split(',')[1] }}</p>
                            </a>
                        <hr class="m-0 p-0">
                    </li>
                </ul>
            </div>
            <!-- <b-button v-if="addressChanged" @click="redirect"> Done </b-button> -->
            <div class="p-5 m-5"></div>
        </div>

        <MainFooter />
        
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import GoogleMaps2 from '../components/GoogleMaps2'
    import OfferTemplate from '../components/OfferTemplate'
    import MainFooter from '../components/footer.vue'

    export default {
        name: 'offerView',
        data() {
            return {
                isLoading: true,
                data: [],
                searchString: '',
                addressChanged: false,
                loading: false,
                prevRoute: null,
                search: '',
                mapLocations: [
                    { address: 'East legon, Accra', location: {"lat":5.6365543,"lng":-0.1623539}},
                    { address: 'A N C Mall, East legon', location: {"lat":5.6415757,"lng":-0.1526023}},
                    { address: 'Adjiringanor, East legon', location: {"lat":5.6519257,"lng":-0.1344516}},
                    { address: 'Legon, Accra', location: {"lat": 5.6487089,"lng":-0.1876909}},
                    { address: 'UPSA, Accra', location: {"lat": 5.6602986,"lng":-0.1705446 }},
                    { address: 'Madina, Accra', location: {"lat": 5.6776436,"lng":-0.1663548}},
                    { address: 'Botwe, Accra', location: {"lat": 5.679295,"lng": -0.137655}},
                    { address: 'Adenta, Accra', location: {"lat": 5.716608,"lng":-0.152484}},
                    { address: 'Haatso, Accra', location: {"lat":5.6761116,"lng":-0.2033109}},
                    { address: 'Marina Mall, Airport', location: {"lat":5.603856,"lng":-0.176897}},
                    { address: 'Accra Mall, Tetteh Quarshie', location: {"lat":5.6219135,"lng":-0.1737838}},
                    { address: 'Palace Mall, Spintex', location: {"lat":5.6257548,"lng":-0.1538399}},
                    { address: 'Cantonments, Accra', location: {"lat":5.6219135,"lng":-0.1737838}},
                    { address: 'Labone, Accra', location: {"lat":5.6219135,"lng":-0.1737838}},
                    { address: 'OxFord Street, Osu', location: {"lat":5.5601211,"lng":-0.1823689 }},
                    { address: 'Tse-addo, Labadi', location: {"lat": 5.584179,"lng":-0.141379}},
                    { address: 'Manet Juntion, Spintex Road', location: {"lat":5.6376157,"lng":-0.1274239}},
                    { address: 'Coastal Juntion, Spintex Road', location: {"lat":5.6344746,"lng":-0.0998379}},
                    { address: 'Sakumono, Tema', location: {"lat":5.625789,"lng":-0.059697}},
                    { address: 'Junction Mall, Nungua Barrier', location: {"lat":5.6135117,"lng":-0.0746697}},
                    // { address: 'West Hills Mall, Weija', location: {"lat":5.5468439,"lng":-0.3468371}},
                ],
            }
        },
        components: {
            GoogleMaps2,
            OfferTemplate,
            MainFooter
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevPath() { return this.prevRoute ? this.prevRoute : '/' },
            
            address() {
                return this.$store.getters['offers/address']
            },

            allOffers() {
                return this.$store.getters['offers/getOffers']
            },
            cartItems() {
                return this.$store.getters['cart/cartCount']
            }
        },
        watch: {
            search() {
                if (this.searchString.length == 0) {
                    this.data = []
                }

                const data = {
                    link: 'offers',
                    data: this.search
                }
                
                this.$store.dispatch('pages/setPage', data)
            },
            address(){
                if (this.address.address){
                    
                    this.$buefy.toast.open('Successfull')
                    //
                    this.addressChanged = true;
                    
                    //
                    this.getOffers();
                }
            },
            allOffers() {

                const data = this.$store.getters['pages/getPage']('offers')

                // if (data !== null) {
                //     this.search = data
                //     this.onSearch()
                // }

            }
        },
        mounted() {

            //
            console.log(this.$route)

            if (!this.address.address) {
                // this.$buefy.toast.open('Kindly search for nearest Landmark or turn on location! ')
            }

            if (Object.keys(this.$route.query).length !== 0) {
            
                //
                this.getShopOffers(this.$route.query.shop)

                setTimeout(()=>{
                    // this.search = this.$route.query.shop
                    this.onSearch()
                }, 800)
                
                
            }

            if (Object.keys(this.$route.params).length !== 0) {

                // console.log(this.$route.params)
                //
                this.getShopOffers(this.$route.params.shop)

                setTimeout(()=>{
                    this.search = this.$route.params.search
                    this.onSearch()
                }, 800)

                const data = {
                        link: 'offers',
                        data: this.$route.params.search
                    }
                
                this.$store.dispatch('pages/setPage', data)

            }

            else {

                const data = this.$store.getters['pages/getPage']('offers')

                console.log(data)

                if ( Object.keys(this.$route.query).length == 0) {

                    if (data !== null) {
                        //
                        this.isLoading = false
                        //
                        if (this.address.address.trim()) {
                            this.getOffers()
                        } else {
                            // this.getAllOffers()
                        }

                        // setTimeout(()=>{
                            
                            this.search = data

                            if (data) {
                                this.onSearch()
                            }
                        // 

                        // }, 1200)

                    } else {
                        this.getOffers();
                    }

                } 

                
            }
        },
        methods: {
            getAllOffers() {
                this.$store.dispatch('offers/getOffers')
                this.isLoading = false
            },
            getShopOffers(shop){
                this.$store.dispatch('offers/getShopOffers', shop)
                this.isLoading = false
            },
            getOffers() {
                this.$store.dispatch('offers/getAllOffers')
                this.isLoading = false
            },
            redirect() {
                this.$router.back()
                // this.$router.push('home')
            },
            onSubmit() {
                if (this.searchString.length >= 3) {
                    console.log(this.searchString)
                }
            },
            onSearch() {
                // console.log('here')
                if (this.search.length >= 2) {
                    
                   
                    const result = this.$store.getters['offers/searchMainOffer'](this.search)

                    this.data = result

                    const data = {
                        link: 'offers',
                        data: this.search
                    }
                
                    this.$store.dispatch('pages/setPage', data)
                    
                }
                // console.log(this.address.address.trim() == "")

                if (this.address.address.trim() == "") {
                    const result = this.$store.dispatch('offers/searchOffers', this.search)
                }
                
            }, 
            load() {
                // this.loading = true 
                // setTimeout(()=>{
                    // this.loading = false
                // }, 500)
            },
            setAddress(loc) {
                //
                this.$store.dispatch('offers/addPlace', { address: loc.address, location: loc.location })
                //
                this.getOffers()
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .dark {
        color: $dark;
    }
</style>