import Vue from "vue";
import Vuex from "vuex";

import { alert } from './modules/alert'
import { auth } from './modules/auth'
import { products } from './modules/products'
import { cart } from './modules/cart'
import { orders } from './modules/orders'
// import { vendors } from './modules/vendors'
import { visits } from './modules/visits'
import { pages } from './modules/pages'
import { shops } from './modules/shops'
import { offers } from './modules/offers'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      alert,
      auth,
      shops,
      offers,
      visits,
      products,
      cart,
      orders,
      // vendors,
      pages,
    },
});