<template>
    <div>

    </div>
</template>

<script>
    export default {
        name: "ReferPage",
        mounted() {
            const data = this.$route.query

            console.log(this.$route.params)
                
            if (Object.keys(data).length !== 0) {

                    if (this.$route.query.link) {

                        // localStorage.setItem("refer-link", JSON.stringify(data) )

                        this.$router.push('/')
                    } else {
                        this.$router.push('/')
                    }
                    
            } 

            else if (Object.keys(this.$route.params).length !== 0 && typeof(this.$route.params.name) !== "undefined" && typeof(this.$route.params.referLink) !== "undefined" ) {

                //
                if (this.$route.params.name && this.$route.params.referLink) {

                    localStorage.setItem("refer-link", JSON.stringify({ name: this.$route.params.name, link: this.$route.params.referLink }) )

                    this.$router.push('/')

                } else {
                    
                    this.$router.push('/')
                }
            } 
            
            else {
                    this.$router.push('/')
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>

</style>