<template>

    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
        <feather @click="goBack" type="arrow-left" size="22" class="p-2 m-5 close"></feather>
        <feather @click="goHome" type="home" size="22" class="p-2 m-5 home "></feather>
        
        
        <div class="p-2" v-if="currentShop.name">
            <!-- `${require('@/assets/img/'+currentShop.image+'')}` -->
               <b-image
                    v-if="currentShop.logo"
                    :src="currentShop.logo" :alt="currentShop.name"
                    :ratio="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? '4by3' : '5by3'))"
                ></b-image>
                <div class="card p-5 my-3">
                    <div class="is-pulled-right pt-4" @click="shareShop">
                        <feather size="24" class="has-text-dark mr-3 " type="share-2"></feather>
                    </div>
                    <div class="p-3">
                            <h3 class="title is-4 mb-2">{{ currentShop.name }}</h3>
                            <p class="has-text-grey mb-3"> {{ currentShop.locationName }}  </p>
                            <b-field>
                                 <div :title="currentShop.type" class="tag is-primary is-rounded">{{ currentShop.type.length > 40 ? currentShop.type.substring(0,40)+' ...' : currentShop.type }}</div>
                            </b-field>
                            <b-field v-if="currentShop.rate">
                                <span class="mx-1 has-text-weight-medium">{{ currentShop.rate }}</span>
                                <b-rate
                                :value="currentShop.rate"
                                :icon-pack="packs"
                                :icon="icons"
                                :max="maxs"
                                :size="sizes"
                                :locale="locale"
                                :show-score="score"
                                :custom-text="custom"
                                :show-text="text"
                                :texts="texts"
                                :rtl="isRtl"
                                :spaced="isSpaced"
                                :disabled="isDisabled">
                            </b-rate>
                        </b-field>
                    </div>
                    
                </div>
                <div v-if="false" class="p-5 card mb-3">
                    <div class="p-1">
                        <h3 class="title is-6 mb-2 mx-2">Contact</h3>
                        <!-- <b-field class=""> -->
                            <a class="p-2 is-flex is-align-items-center" :href="`tel:${currentShop.phone.countryCode+currentShop.phone.tel}`">
                                    <feather size="20" class="has-text-dark mr-3" type="phone-call"></feather>
                                    <p class="has-text-dark"> +{{ currentShop.phone.countryCode+currentShop.phone.tel }} </p>
                                </a>

                                <a v-if="currentShop.whatsapp" class="p-2 is-flex is-align-items-center" :href="`https://wa.me/${(currentShop.whatsapp.countryCode+currentShop.whatsapp.tel)}?text=Hello ${currentShop.name}, from Shopnscan.app`" target="_blank">
                                    <!-- <feather size="20" class="has-text-dark mr-3" type="message-circle"></feather> -->
                                    <b-icon type="is-dark" icon="whatsapp" class="mr-2"></b-icon>
                                    <p class="has-text-dark"> +{{ currentShop.whatsapp.countryCode+currentShop.whatsapp.tel }} </p>
                                </a>

                                <a v-if="currentShop.website" class="p-2 is-flex is-align-items-center" :href="`${currentShop.website}`" target="_blank">
                                    <feather size="20" class="has-text-dark mr-3" type="globe"></feather>
                                    <p class="has-text-dark"> {{ currentShop.website }} </p>
                                </a>

                            <!-- <a class="p-2 is-flex is-align-items-center" :href="`tel:${currentShop.phone.countryCode+currentShop.phone.tel}`">
                                    <feather size="20" class="has-text-dark mr-3" type="phone"></feather>
                                    <span class="has-text-dark"> +{{ currentShop.phone.countryCode+currentShop.phone.tel }} </span>
                                </a> -->
                        <!-- </b-field> -->
                        
                    </div>
                </div>
                <div class="p-5 card mt-3">
                    <div class="p-1">
                        <!-- <h3 class="title is-6 mb-2 mx-2 is-flex is-align-items-center "><feather size="20" class="has-text-primary mr-3" type="edit"></feather> Your Review</h3> -->
                        <div class="is-pulled-right" @click="gotoPref">
                            <feather v-if="preferences.length > 0" size="20"  class="has-text-dark mr-3 " type="edit"></feather>
                            <!-- <feather size="20"  class="has-text-dark mr-3 " type="edit"></feather> -->
                            <b-tag  v-else type="is-primary" rounded class="mb-1 has-text-" >Click to set </b-tag>
                        </div>
                        
                        <h3 class="title is-6 mb-2 mx-2">Your Preference</h3> 

                        <div v-if="preferences.length > 0" class="py-3 px-2">
                            
                            <b-button type="is-primary" outlined rounded class="mr-2 mb-3" v-for="pref in preferences" :key="pref" size="is-small">{{ pref }}</b-button>
                        </div>
                        <!-- <div v-else>
                            <b-tag >Set pre</b-tag>
                        </div> -->
                    </div>
                </div>
                <div class="p-5 card mt-3">
                    <div class="p-1">
                        <!-- <h3 class="title is-6 mb-2 mx-2 is-flex is-align-items-center "><feather size="20" class="has-text-primary mr-3" type="edit"></feather> Your Review</h3> -->
                        <div class="is-pulled-right" @click="routeToProduct">
                            <!-- <feather size="20"  class="has-text-dark mr-3 " type="edit"></feather> -->
                            <b-tag rounded type="is-primary" class="mb-1 has-text-" >View items</b-tag>
                        </div>
                        
                        <h3 class="title is-6 mb-2 mx-2">Your Products</h3> 

                        
                        <!-- <div v-else>
                            <b-tag >Set pre</b-tag>
                        </div> -->
                    </div>
                </div>
                <div class="p-5 card mt-3">
                    <div class="p-1">
                        <!-- <h3 class="title is-6 mb-2 mx-2 is-flex is-align-items-center "><feather size="20" class="has-text-primary mr-3" type="edit"></feather> Your Review</h3> -->
                        <h3 class="title is-6 mb-2 mx-2">Your History</h3>
                        <p class="p-2 mb-2"> {{ visits }} Orders</p> 
                            <!-- <a class="p-2 is-flex is-align-items-center" :href="`tel:${currentShop.phone.countryCode+currentShop.phone.tel}`">
                                    <span class="has-text-dark"> +{{ currentShop.phone.countryCode+currentShop.phone.tel }} </span>
                                </a> -->
                        <!-- <h3 class="title is-6 mb-2 mx-2">Total Ratings</h3> -->
                        <b-field>
                                <!-- <span class="mx-2 has-text-weight-medium">3.5</span> -->
                                <b-rate
                                :value="visitRates"
                                :icon-pack="packs"
                                :icon="icons"
                                :max="maxs"
                                :size="sizes"
                                :locale="locale"
                                :show-score="true"
                                :custom-text="'3.5 Rated'"
                                :show-text="true"
                                :texts="texts"
                                :rtl="isRtl"
                                :spaced="isSpaced"
                                :disabled="isDisabled">
                            </b-rate>
                        </b-field>
                    </div>
                </div>
                
        </div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from "vuex"

    export default {
        name: "ShopPage",
        data() {
            return {
                isLoading: true,
                isFullPage: true,
                screenWidth: 0,
                preferencesId: null,
                preferences: [],
                visits: 0,
                visitRates: 0,
                currentShop: {
                    id: '',
                    name: '',
                    cover: '', 
                    phone: '', 
                    logo: '',
                    locationName: '', 
                    location: '',
                    rate: '',
                    type: '',
                    website: '',
                    categories: '',
                    available: 0,
                    active: 1,
                },
                cartSubmitted: false,
                rate: 4.6,
                maxs: 5,
                sizes: '',
                packs: 'mdi',
                icons: 'star',
                score: false,
                custom: '',
                text: false,
                texts: ['Very bad', 'Bad', 'Good', 'Very good', 'Awesome'],
                isRtl:false,
                isSpaced: false,
                isDisabled: true,
                locale: undefined // Browser locale
            }
        },
        computed: {
            // ...mapGetters('products', ['allProducts']),
            ...mapGetters('cart', ['getCartBusiness']),
            
            alreadyInCart() {
                // console.log(this.$store)
                return this.$store.getters['cart/alreadyInCart'](this.currentShop.id)
            },
            inCart() {
                // console.log(this.$store)
                const cart = this.$store.getters['cart/inCart'](this.currentShop.id)
                return cart
            },
            // alreadyAddedtoWishlist() {
            //     return this.$store.getters.cart.alreadyInWishlist(this.id)
            // } 
        },
        mounted(){
            console.log(this.$route)
            //
            // this.currentShop = this.$route.params.product

            //
            if (Object.keys(this.$route.query).length !== 0) {

                //
                axios.get('/shop?shop='+this.$route.query.shop).then((res)=>{
                    //
                    this.currentShop = res.data[0];

                    this.isLoading = false

                    this.checkShop()
                    this.checkVisit()
                })

                // this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"shop" } ).then(()=>{
                //     //
                //     console.log(this.$store.getters['shops/getShops'][0])
                //     //
                //     this.currentShop = this.$store.getters['shops/getShops'][0]
                // })
            }

            else if (Object.keys(this.$route.params).length !== 0 && typeof(this.$route.params.shopName) !== "undefined" ) {

                //
                axios.get('/shop?shop='+this.$route.params.shopName).then((res)=>{
                    //
                    this.currentShop = res.data[0];

                    this.isLoading = false

                    this.checkShop()
                    this.checkVisit()
                })

                // this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"shop" } ).then(()=>{
                //     //
                //     console.log(this.$store.getters['shops/getShops'][0])
                //     //
                //     this.currentShop = this.$store.getters['shops/getShops'][0]
                // })
            }
            
            else if (Object.keys(this.$route.params).length !== 0) {
                
                const data = {
                        link: 'shop',
                        data: this.$route.params
                    }

                this.currentShop.id = this.$route.params.id
                this.currentShop.name = this.$route.params.name
                this.currentShop.cover = this.$route.params.cover
                this.currentShop.phone = this.$route.params.phone
                this.currentShop.country = this.$route.params.country
                this.currentShop.whatsapp = this.$route.params.whatsapp
                this.currentShop.logo = this.$route.params.logo
                this.currentShop.locationName = this.$route.params.locationName
                this.currentShop.location = this.$route.params.location
                this.currentShop.rate = this.$route.params.rate
                this.currentShop.type = this.$route.params.type
                this.currentShop.website = this.$route.params.website
                this.currentShop.categories = this.$route.params.categories
                this.currentShop.available = this.$route.params.available
                this.currentShop.active = this.$route.params.active

                this.$store.dispatch('pages/setPage', data)

                this.isLoading = false

                this.checkShop()
                this.checkVisit()
            }
            else {
                const data = this.$store.getters['pages/getPage']('shop')

                if (data !== null) {
                        // this.form = data.search
                        this.currentShop.id = data.id
                        this.currentShop.name = data.name
                        this.currentShop.cover = data.cover
                        this.currentShop.phone = data.phone
                        this.currentShop.country = data.country
                        this.currentShop.whatsapp = data.whatsapp
                        this.currentShop.logo = data.logo
                        this.currentShop.locationName = data.locationName
                        this.currentShop.location = data.location
                        this.currentShop.rate = data.rate
                        this.currentShop.type = data.type
                        this.currentShop.website = data.website
                        this.currentShop.categories = data.categories
                        this.currentShop.available = data.available
                        this.currentShop.active = data.active
                }

                this.isLoading = false

                this.checkShop()
                this.checkVisit()
            }
        

            this.updateScreenWidth();
            this.onScreenResize();
            
            // setTimeout(()=>{
                
            // }, 500)
        },
        methods: {
            checkShop() {
                //
                axios.get('/user/pref/'+this.currentShop.name).then(response=>{
                    //
                    const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    this.preferences = shop.preferences
                    this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.$buefy.toast.open("Set shopping preference below.")
                })
            },
            checkVisit() {
                //
                axios.get('/shop/visit?shop='+this.currentShop.name).then(response=>{
                    //
                    this.visits = response.data.length
                    this.visitRates = response.data.reduce((a, b) => a + b.rate, 0) !== 0 ? response.data.reduce((a, b) => a + b.rate, 0) / this.visits : 0

                    
                    // const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    // this.preferences = shop.preferences
                    // this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            gotoPref() {
                //
                this.$router.push({ 
                                        name: 'setuppref',
                                        params: { 
                                            editPref: this.preferences ? this.preferencesId : false, 
                                            shop: this.currentShop, prefs: this.preferences ? this.preferences : false 
                                        },
                                        
                                  })
            },
            routeToProduct(data) {

                //
                this.$router.push({ name: 'products', params: this.currentShop })

            },
            shareShop() {

                this.$buefy.dialog.confirm({
                    message: `<b>Refer a Friend to ${this.currentShop.name}</b><br><br>Receive <b>10 Points</b> for every referal. Your referred friends will enjoy <b>5 Points</b> on signup. <b>Redeem Gifts, Discounts & Special Offers</b> with points!`,
                    confirmText: 'Copy link',
                    cancelText: 'Cancel',
                    onConfirm: () => {
                        this.$buefy.toast.open('User agreed')

                        this.$copyText(`https://app.shopnscan.app/refer?link=${(this.$store.getters['shops/generateShopHash'](this.$store.getters['auth/getUser'].name)).substring(0,10)}&shop=${this.currentShop.name}`).then( (e)=>{
                            this.$buefy.toast.open(`Refer link copied!`)
                        }, (e)=> {
                            this.$buefy.toast.open(`Failed to copy Refer link!`)
                        })
                    }
                })

            }
            
        }
    }
</script>

<style lang="scss" scoped>

</style>